// Breakpoints
$breakpoint-mobile: 767px;   
$breakpoint-tablet: 1023px; 

// Mixin for media queries
@mixin media($device) {
  @if $device == mobile {
    @media (max-width: #{$breakpoint-mobile}) {
      @content;
    }
  } @else if $device == tablet {
    @media (min-width: #{$breakpoint-mobile + 1}) and (max-width: #{$breakpoint-tablet}) {
      @content;
    }
  } @else if $device == desktop {
    @media (min-width: #{$breakpoint-tablet + 1}) {
      @content;
    }
  }
}
