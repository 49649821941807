@import "../../Styles/variables";
@import "../../Styles//media";

h2{
    font-size: 40px !important;
    @include media(mobile){
        font-size: 32px !important;
    }
}

// .contact .line__style{


// }

.contact {
    color: white;
    background:#3B3A38;
    display: flex;
    flex-direction: column;
    padding-bottom: 20px;
   

    &__image{
        width: 100%;
        height: 100%; 
        object-fit: cover; 
        filter: brightness(80%);
    }
    &__wrapper{
        padding: 20px 15px;
        display: flex;
        flex-direction: column;
        align-items: center;
        position: relative;
        z-index: 2;
        @include media(tablet)  {
            padding: 15px 50px;
        }
        @include media(desktop)  {
            padding: 15px 60px;
        }
    }
    &__right_TopText{

    }   

    &__right_title{
        line-height: 45px;
        margin-bottom: 5px;

    }
    &__right_text{
        margin-bottom: 15px;
        font-size: 18px;
        text-align: center;
        max-width: 450px;
    }
    &__right_options{
        display: flex;
        flex-direction: row;
        align-items: center;
        svg{
            margin-right: 10px;
        }
    }
    &__form_wrapper{
        //height: 340px;
        //background-color: #D9D9D9;
    }
    &__form{
        padding: 0px 15px;
        padding-bottom: 20px;
        @include media(tablet)  {
            padding: 15px 50px;
        }
        @include media(desktop)  {
            padding: 15px 60px;
        }
    }
    &__title{
        font-size: 32px;
        font-weight: 700;
        text-transform: uppercase;
    }
    &__form_fields{
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        overflow: hidden;
        margin-top: 15px;
    }
    &__form_row{
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        width: 100%;
        margin-bottom: 5px;
    }
    &__form_group{
        width: 45%;
        overflow: hidden;
    }
    &__form_group_message{
        width: 100%;
        display: flex;
        flex-direction: column;
        max-height: 85px;
    }
    &__submit_button{
        margin-top: 20px;
        position: relative;
        display: block;
        background-color: #dfb36e;
        color: white;
        font-weight: 700;
        height: 36px;
        width: auto;
        padding: 0px 25px;
        border: none;
        border-radius: 18px;
        font-size: 18px;
        margin-top: 20px;
        margin-left: auto;
        margin-right: auto;
        cursor: pointer;
    &:hover{
        text-decoration: underline;
    }
    }
    &__required{
        color: red;
        margin-left: 2px;
    }
    &__form_wrapper{
        border-radius: 20px;
        margin: 10px auto;
        width: 90%;
        color: #3B3A38;
        background: white;
                input,
                textarea {
                    border: none;
                    border-bottom: 1px solid #333; /* Keep only the bottom border */
                    padding: 5px;
                    font-size: 16px;
                    background: #efefef;
                    color: #333;
    
                    &:focus {
                        outline: none;
                        border-color: $primary-color;
                    }
                }
                label{
                    font-weight: 100;
                    display: flex;
                    align-items: center;
                    svg{
                        margin-right: 5px;
                        font-weight: 700!important;
                    }
                }
    }
    @include media(tablet)  {
        &__form_group{
            width: 45%;
            overflow: hidden;
            display: flex;
            flex-direction: column;
        }
           

    }
    @include media(desktop)  {
        &__image_wrapper{
            width: 40%;
            display: flex;
            flex-direction: column;
            justify-content: center;
        }

        &__form_wrapper{
            width: 50%;
        }
        &__form_group{
            width: 45%;
            overflow: hidden;
            display: flex;
            flex-direction: column;
        }
    }

}
@include media(desktop)  {
    .contact{
         display: flex;
         flex-direction: row;
         padding-top: 20px;
     }
     

 }