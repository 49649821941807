@import "../../Styles/variables";
@import "../../Styles//media";

.footer {
  background-color: $tertiary-color;
  color: $primary-color;
  border-top: 2px solid #DFB36E;

  &__content {
    margin: 0 auto;
    padding: 20px 15px;
    
  }
  &__section_wrapper{
    display: flex;
    flex-direction: column;
  }
  &__content_wrapper{
    display: flex;
    flex-direction: row-reverse;
    align-items: start;
    justify-content: space-between;
  }

 &__logo{
    width: 100px;
    align-items: center;
    text-align: center;
    display: flex;
    justify-content: center;

}
&__section{
    margin-bottom: 10px;
}
&__section_title{
    font-size: 18px;
    font-weight: 700;
}
&__rights{
width: 100%;
text-align: center;
font-weight: 700;
}
&__section_wrapper{
    margin-left: 10px;
    &.icon{
        display: flex;
        margin-left: 0;
        justify-content: center;
        margin-top: 20px;
        flex-direction: row;
        svg{
            width: 40px;
            height: 40px;
            margin-right: 10px;
            color: $primary-color;
        }
    }
}
&__section{
    &.icons{
        display: flex;
        flex-direction: column;
    }
}
.line__style__footer{
    background: $secondary-color;
    display: block;
    width: 100%;
    height: 2px;
    margin-top: 5px;
    margin-bottom: 10px;
}
&__logo.desktop{
    display: none;
}


  // Responsive styles
  @include media(tablet)  {
    padding: 16px 25px;

    &__section_wrapper{
        flex-direction: row;
        justify-content: space-between;
        line-height: 1.8;
    }

    &__section_wrapper.desktop{
        flex-direction: column;
    }

    &__logo.mobile{
        display: none;
    }
    &__logo.desktop{
        display: block;
        width: 80px;
        margin-left: 15px;
       cursor: pointer; 
       img{
        height: 80px;
    align-items: center;
    text-align: center;
    display: flex
;
    justify-content: center;
       }
    }
    &__rights{
        text-align: start;
        margin-left: 10px;
    }
    &__content{
        padding: 0px 15px;
    }
    &__section_wrapper.icon{
    display: unset;
    margin-top: 10px;
    margin-left: 10px;
    }
  }

  @include media(desktop)  {
    padding: 16px 25px;

    &__section_wrapper{
        flex-direction: row;
        justify-content: space-between;
        line-height: 1.8;
    }

    &__section_wrapper.desktop{
        flex-direction: column;
    }

    &__logo.mobile{
        display: none;
    }
    &__logo.desktop{
        display: block;
        width: 80px;
        margin-left: 35px;
        cursor: pointer;
        img{
            height: 80px;
        align-items: center;
        text-align: center;
        display: flex
    ;
        justify-content: center;
           }
    }
    &__rights{
        text-align: start;
        margin-left: 10px;
    }
    &__content{
        padding: 0px 35px;
    }
    &__section_wrapper.icon{
    display: unset;
    margin-top: 10px;
    margin-left: 10px;
    }
  }


}
