// Colors
$primary-color: #363636;
$secondary-color: #DFB36E;
$tertiary-color: #f9f9f9;

// Fonts
$base-font-size: 16px;
$heading-font: 'lato', sans-serif;

// Spacing
$spacing-small: 8px;
$spacing-medium: 16px;
$spacing-large: 24px;