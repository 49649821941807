@import "../../Styles/variables";
@import "../../Styles//media";

.howWework {
    position: relative;
    color: #fff;
    background-image: url(../../Assets/Images/howweworkMobile.png);
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    height: auto;

    &::before {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
  
      background-image: inherit;
      background-size: inherit;
      background-position: inherit;
      background-repeat: inherit;
  
      filter: brightness(0.8);
      z-index: 0;
      @include media(tablet){
        filter: brightness(0.5)
      }
      @include media(desktop){
        filter: brightness(0.5)
      }
    }
  
    > * {
      position: relative;
      z-index: 1;
    }
    &__wrapper{
        display: flex;
        flex-direction: column;
        height: 100%;
        @include media(mobile){
            width: 80vw;
            margin: 0 auto;
            padding-top: 20px;
        }
    }

    &__step{
        font-weight: 700;
        font-size: 20px;
    }

    &__title{
        font-size: 32px;
        font-weight: 700;
        text-align: center;
        margin-bottom: 10px;
        padding-top:8px;
    }
    &__description{
        text-align: left;
    }

    &__sub{
        text-align: center;
    }

    &__sections_wrapper{
        display: flex;
        flex-direction: column;
        padding: 35px 15px;
        align-items: center;
        height: 100%;
    }
    @include media(desktop)  {
        &__sections_wrapper{
            flex-direction: row;
            justify-content: space-evenly;
        }
        &__section{
            width: 300px;
        }
        svg{
            transform: rotate(270deg)
        }
        
    }
    @include media(tablet)  {
        &__sections_wrapper{
            width: 70%;
            margin: 0 auto;
        }
    }
    .line__style__work{
        background: $secondary-color;
        display: block;
        margin: 0 auto;
        width: 40%;
        height: 3px;
        margin-bottom: 10px;
        margin-top: 5px;
    }
}
@include media(desktop)  {
    .howWework {
        background-image: url(../../Assets/Images/howweworkDesktop.png);
        height: 380px;
    }
}

   
