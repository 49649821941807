@import "../../Styles/variables";
@import "../../Styles/media";


.gallery {
  padding: 5px 15px;

  &__wrapper {
    display: flex;
    flex-direction: column;
    height: 100%;
    align-items: center;
  }

  &__tittle {
    font-size: 32px;
    font-weight: 700;
    text-align: center;
    margin-bottom: 10px;
    color:#3B3A38;
  }

  &__pics_wrapper {
    display: flex;
    flex-direction: column;
    width: 100%;
    align-items: center;
    max-width: 850px;
  }

  &__pics {
    height: 250px;
    width: 300px;
    margin-bottom: 20px;
    background-size: cover;
    background-position: center;
    cursor: pointer;
    border-radius: 5px;
    position: relative;
    overflow: hidden;

    &:first-child {
      margin-top: 20px;
    }

    &::after {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: linear-gradient(to top, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0));
      z-index: 1;
    }

    .gallery__title {
      position: absolute;
      bottom: 40%;
      left: 50%;
      transform: translateX(-50%);
      color: #fff;
      font-size: 20px;
      font-weight: 700;
      text-align: center;
      z-index: 2; 
      padding: 5px 10px;
      border-radius: 5px;
      max-width: 90%;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;

    }
  }

  @include media(mobile) {
    &__pics_wrapper {
      flex-direction: row;
      flex-wrap: wrap;
      justify-content: space-around;
      margin-bottom: 20px;
    }

    &__pics {
      margin-bottom: unset;
      margin-top: 25px;

      &:first-child {
        margin-top: 25px;
      }
    }
  }

  @include media(desktop) {
    &__pics_wrapper {
      flex-direction: row;
      flex-wrap: wrap;
      justify-content: space-around;
      margin-bottom: 20px;
    }

    &__pics {
      margin-bottom: unset;
      margin-top: 25px;

      &:first-child {
        margin-top: 25px;
      }
    }
  }
}

.line__style__gallery {
  background: #dfb36e;
  display: block;
  margin: 0 auto;
  width: 40%;
  height: 2px;
  margin-bottom: 10px;
  margin-top: 5px;
}

.PhotoView-Slider__Backdrop {
  background: rgba(0, 0, 0, 0.5) !important;
}

.photo-view-arrows {
  display: block !important;
}