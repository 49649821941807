@import "../../Styles/variables";
@import "../../Styles/media";

.services {
  padding: 20px;

  &__wrapper {
    margin: 0 auto;
    text-align: center;
    align-items: center;
  }

  &__title {
    font-size: 2rem;
    font-weight: bold;
    margin-bottom: 10px;
  }

  &__toptext{
    color: white;
    font-size: 18px;
    font-weight: 700;
    text-align: center;
    z-index: 2;
    padding: 5px 10px;
    border-radius: 5px;
  }

  &__bottext{
    color: white;
    font-size: 12px;
  }

  &__textcont{
    background: #3B3A38;
    padding: 0px 15px;
    position: absolute;
    left: 0;
    right: 0;
    border: 1px solid black;
    bottom: 12px;
    display: flex;
    justify-content: center;
    flex-direction: column;
    min-height: 160px;
    z-index: 1;
  }

  &__image{
    position: relative;
    min-height: 300px;
  }

  .line__style__services {
    background: #dfb36e;
    display: block;
    margin: 0 auto;
    width: 40%;
    height: 2px;
    margin-bottom: 10px;
    margin-top: 5px;
  }

  &__description {
    font-size: 1rem;
    margin-bottom: 20px;
    color: #333;
    max-width: 800px;
    margin: 0 auto;
    margin-bottom: 20px;
  }

  &__link {
    display: inline-block;
    margin-bottom: 20px;
    font-weight: bold;
    color: #000;
    text-decoration: underline;
    cursor: pointer;

    &:hover {
      color: #f0c040;
    }
  }

  &__images {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 10px;
    max-width: 800px;
  }

    &__image {
      img {
        width: 90%;
        height: auto;
        border-radius: 5px;
        object-fit: cover;
        // max-width: 112px;
        max-height: 150px;
        position: relative;
        z-index: 2;
    
      }
    }
  
  @include media(tablet)  {
    &__image {
        img {
            width: 100%;
            height: auto;
            border-radius: 5px;
            object-fit: cover;
    
            max-width: 200px;
            max-height: 150px;
          }
    }
    &__images {
        grid-template-columns: repeat(3, 1fr);
        
}
}
    @include media(desktop){
        &__image {
            img {
                width: 100%;
                height: auto;
                border-radius: 5px;
                object-fit: cover;
        
                max-width: 200px;
                max-height: 150px;
              }

        }
        &__wrapper{
            display: flex;
            width: 100%;
            flex-direction: column;
        }
        &__wrapper_first{
            display: flex;
            flex-direction: column;
            width: 100%;
        }
        &__images{
            width: 100%;
            grid-template-columns: repeat(3, 1fr);
        }
    }
}


.line__style__services{
    background: #DFB36E;
    display: block;
    margin: 0 auto;
    width: 40%;
    height: 2px;
    margin-bottom: 10px;
    margin-top: 5px;
}