@import "../../Styles/variables";
@import "../../Styles/media";

.reviews {
  background-image: url('../../Assets/Images/reviewsImg.png');
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  height: 480px;
  display: flex;
  padding: 5px 15px;
  margin-bottom: 1px;
  flex-direction: column;
  justify-content: space-around;
  overflow-x: hidden; 

  &__title {
    font-size: 32px;
    color: #fff;
    font-weight: 700;
    text-align: center;
    width: 100%;
    margin-bottom: 25px;
    line-height: 1.2;
  }

  &__review-container {
    background-color: #f8f8f8;
    padding: 20px;
    border-radius: 5px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  }

  &__container_Wrapper {
    width: auto;
    background: white;
    display: flex;
    flex-direction: column;
    padding: 10px;
    align-items: center;;
    border-radius: 5px;
    width: 100%;
  }

  &__topContent {
    display: flex;
    width: 60%;
    white-space: normal;

  }

  &__midContent {
    display: flex;
    height: 80%;
    max-height: 115px;
    align-items: center;
    max-width: 100%; /* Prevent fixed widths from causing overflow */
  word-wrap: break-word;
    p{
      width: 300px;
      text-align: center;
      max-height: 100px;
      overflow: hidden;
    }
  }

  &__botContent {
    text-align: right;
    width: 100%;
    margin-top: 15px;
    display: flex;
    justify-content: space-between;
    align-items: end;
    div{
      display: flex;
      line-height: initial;
      .reviews__profile-photo{
        width: 45px;
        margin-right: 10px;
        display: none;
      }
    }
    img{
      height: 20px;
      width: 20px;
    }

  }
  &__reviewsAndName{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }
  &__date{
    font-size: 12px;
  }
  &__name{
    font-weight: 700;
  }
  @include media(desktop) {
    height: 440px;
    background-image: url('../../Assets/Images/reviewsDesktop.png');
    flex-direction: column;

    &__title {
      font-size: 40px;
    }
  }

  @include media(tablet) {
    &__title {
      font-size: 40px;
    }
  }

  .swiper-wrapper {
    width: 300px;
    border-radius: 12px!important;
    @include media(tablet){
      width: 400px!important;
    }
    @include media(dekstop){
      width: 450px!important;
    }
  }
  .swiper-slide{
    border-radius: 12px;
    display: flex;
        flex-direction: column;
        justify-content: center;
        
  }


  .review__stars {
    display: flex;
    justify-content: center;
    width: 100%;
    margin-bottom: 15px;
  }

  .star {
    display: inline-block;
    width: 24px;  
    height: 24px; 
    margin-right: 4px;
    color: #fdd663;
  }


  .star.full {
    color: #fdd663
  }

  .star.half {
    color: #fdd663 
  }

  .star.empty {
    color: #9e9e9e;
  }
}

.moreReviews{
  text-align: center;
  color: white;
  margin-top: 20px;
  cursor: pointer;
  font-weight: 700;
  text-decoration: underline;
  font-size:18px;
}