@import "../../Styles/variables";
@import "../../Styles//media";

.about {
    position: relative;
    display: flex;
    height: fit-content;
    justify-content: space-evenly;
    align-items: center;
    color: black;
    flex-direction: column;
    width: 80vw;
    margin: 30px auto;

    &__heading{
        color: #3B3A38;
        font-weight: 700;
        font-size: 40px;
        margin-bottom: 10px;
        text-align: center;

        @include media (mobile) {
            line-height: 45px;
          }
    }

    &__text {
        position: relative;
        z-index: 1;
        font-size: 19px;
        text-align: center;
        margin-bottom: 20px;
    }
}

.about__toggle {
    background-color: transparent;
    border: none;
    color: #ac8a53;
    cursor: pointer;
    font-size: 1.3rem;
    margin-top: 1rem;
    text-decoration: none;
    font-weight: 700;
  }
  
  .about__toggle:hover {
    color: #ac8a53;
  }

  @include media (desktop) {
    .about__toggle {
      display: none;
    }
  }

  @include media (tablet) {
    .about__toggle {
      display: none;
    }
  }

  .about .line__style__work{
    background: #DFB36E;
    display: block;
    margin: 0 auto;
    width: 40%;
    height: 3px;
    margin-bottom: 10px;
    margin-top: 5px;
  }
  
